<template>
  <vue-final-modal
    :modelValue="showModal"
    :focus-trap="false"
    class="modal-email-verify"
    :clickToClose="false"
    :overlayTransition="{ mode: 'in-out', duration: 200 }"
    :contentTransition="{ mode: 'in-out', duration: 200 }"
  >
    <div class="scroll">
      <modal-content-email-verify
        @close="emit('close')"
        :email="email"
      />
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';

  defineProps<{
    showModal: boolean,
    email: string
  }>();

  const emit = defineEmits(['close']);
</script>

<style src="~/assets/styles/components/modal/email-verify.scss" lang="scss" />

