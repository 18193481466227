<template>
  <div class="modal-email__form">
    <form-email-code-verify
      :hint="verificationError"
      :loading="verifyLoading"
      :buttonSubmitLabel="getContent(layoutData, defaultLocaleLayoutData, 'buttons.submit')"
      :buttonCancelLabel="getContent(layoutData, defaultLocaleLayoutData, 'buttons.back')"
      @remove-hint="verificationError = undefined"
      @cancel="emit('close')"
      @verifyCode="verifyCode"
      @resendCode="resendVerifyCode()"
    />
  </div>
</template>

<script setup lang="ts">
  import type { IFieldHint } from '~/types';

  const layoutStore = useLayoutStore();
  const { showAlert } = layoutStore;
  const globalStore = useGlobalStore();
  const { getContent } = useProjectMethods();
  const { emailOtpCodeVerification, sendEmailVerifyCode } = useCoreAuthApi();

  const {
    alertsData,
    defaultLocaleAlertsData,
    layoutData,
    defaultLocaleLayoutData,
    fieldsSettings,
    defaultLocaleFieldsSettings,
  } = storeToRefs(globalStore);

  const { getProfileData } = useProfileStore();

  const props = defineProps<{
    email: string;
  }>();

  const emit = defineEmits(['close']);

  const verificationError = ref<Maybe<IFieldHint>>();

  const verifyLoading = ref(false);

  const resendVerifyCode = async () => {
    await sendEmailVerifyCode({ email: props.email });
    showAlert(alertsData.value?.profile?.sentCodeVerify || defaultLocaleAlertsData.value?.profile?.sentCodeVerify);
  };

  const verifyCode = async (verificationCode: string): Promise<void> => {
    if (verifyLoading.value) return;
    verifyLoading.value = true;

    try {
      await emailOtpCodeVerification({
        email: props.email,
        code: verificationCode,
      });

      await getProfileData();
      useEvent('profileUpdated');
      showAlert(alertsData.value?.profile?.successAddEmail || defaultLocaleAlertsData.value?.profile?.successAddEmail);
      emit('close');
    } catch (error: any) {
      let message = '';

      if (error.data?.error?.code === 11003) {
        message = getContent(
          fieldsSettings?.value,
          defaultLocaleFieldsSettings?.value,
          'forms.verification.invalidError'
        );
      } else {
        message =
          error.data?.error?.message ||
          getContent(
            fieldsSettings?.value,
            defaultLocaleFieldsSettings?.value,
            'forms.verification.cantVerifyEmailCode'
          );
      }

      if (error.status === 429) {
        showAlert(
          alertsData?.value?.profile?.tooManyAttempts || defaultLocaleAlertsData?.value?.profile?.tooManyAttempts
        );
      }

      verificationError.value = {
        variant: 'error',
        message,
      };
    }
    verifyLoading.value = false;
  };
</script>

<style src="~/assets/styles/components/modal-content/bonus-condition.scss" lang="scss" />
